import axios from 'axios'
import { MessageBox, Message } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent


    // 默认请求出错弹窗提示，加上 withoutMessage 配置不提示
    if (config.withoutMessage) {
      service.responseWithoutMessage = true;
    }

    // 默认不处理 error 不等于 0的情况，加上 handleError 后, error 不等于0的情况 resolve 处理
    if (config.handleError) {
      service.handleError = true;
    }

    if (config.isNotJson) {
      service.isNotJson = true;
    }

    return config;
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.headers['content-type'].match(/application\/json/)) {
      const res = response.data;

      if (service.handleError) {
        return res;
      } else {
        if (res.error !== 0) {
          console.error('请求失败：', res.message);

          // 弹窗提示
          if (!service.responseWithoutMessage) {
            Message({
              message: res.message,
              type: 'error',
              duration: 3 * 1000
            });
          } else {
            service.responseWithoutMessage = false;
          }

          res.toString = () => {
            return res.message;
          }
          return Promise.reject(res);
        } else {
          return res;
        }
      }
    } else {
      if (service.isNotJson) {
        return response;
      } else {
        console.error('数据类型错误') // for debug
        // 弹窗提示
        if (!service.responseWithoutMessage) {
          Message({
            message: '服务器繁忙，请稍后再试',
            type: 'error',
            duration: 3 * 1000
          });
        } else {
          service.responseWithoutMessage = false;
        }
        throw new Error('服务器繁忙，请稍后再试');
      }
    }
  },
  error => {
    console.error('请求错误：', error) // for debug

    // 弹窗提示
    if (!service.responseWithoutMessage) {
      Message({
        message: '服务器繁忙，请稍后再试',
        type: 'error',
        duration: 3 * 1000
      });
    } else {
      service.responseWithoutMessage = false;
    }

    return Promise.reject(new Error('服务器繁忙，请稍后再试'));
  }
)

// 对 catch 信息过滤
const request = (config) => {
  return new Promise((resolve, reject) => {
    service(config)
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        if (config.throwCatch) {
          reject(error);
        }
      })
  })
};

export default request

<template>
  <div>index</div>
</template>

<script>

export default {
  name: "index",
  data() {
    return {

    }
  },
}
</script>

<style scoped lang="less">

</style>

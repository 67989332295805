<template>
  <div class="wrap">
    <div class="box">
      <div class="list-wrap">
        <div class="title">信息列表</div>
        <div class="list" v-loading="dataLoading">
          <div
              class="item"
              v-for="item in data"
              :key="item.type + item.id"
              @click="showChatList(item)"
          >
            {{ item.type }}, {{ item.id }}
          </div>
        </div>
      </div>
      <div class="chat-wrap">
        <div class="title">聊天详情</div>
        <div class="chat" v-loading="chatLoading">
          <div
              class="item"
              v-for="(item, index) in chatList"
              :key="index"
          >
            {{ item.type }}, {{ item.text.substr(0, 24) }}, {{ item.files.length }}
          </div>
        </div>
        <div class="input-wrap">
          <el-input type="area" v-model="text" placeholder="输入信息" ></el-input>
          <el-button size="small" type="primary" @click="reply()" :disabled="!currentItem">回复信息</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "push",
  data() {
    return {
      dataLoading: false,
      chatLoading: false,
      data: [],
      currentItem: null,
      chatList: [],
      text: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      this.dataLoading = true;
      request({
        url: '/list',
      })
          .then(res => {
            this.data = res.data;
            this.dataLoading = false;
            if (this.currentItem) {
              if (this.data.find(item => item.type === this.currentItem.type && item.id === this.currentItem.id)) {
                this.showChatList(this.currentItem);
              }
            }
          });
    },
    // 获取聊天列表
    getChat(type, id) {
      this.chatLoading = true;
      request({
        url: `/chat/${type}/${id}`,
      })
          .then(res => {
            this.chatList = res.data;
            this.chatLoading = false;
          });
    },
    // 推送信息
    pushText(type, id) {
      request({
        url: `/push/${type}/${id}`,
        method: 'post',
        data: {text: this.text}
      })
          .then(res => {
            this.text = '';
            this.$successMessage("提交成功");
            this.getList();
          })
    },
    showChatList(item) {
      this.currentItem = item;
      this.getChat(item.type, item.id);
    },
    reply() {
      if (this.currentItem) {
        this.pushText(this.currentItem.type, this.currentItem.id);
      }
    },
  },
}
</script>

<style scoped lang="less">
@boxHeight: 450px;
@titleHeight: 28px;
@inputHeight: 40px;
.wrap{
  text-align: left;
  box-sizing: border-box;
  .box{
    display: flex;
    height: @boxHeight;
    & > div{
      border: 1px solid #d7d7d7;
    }
    .title{
      font-weight: bold;
      height: @titleHeight;
      line-height: @titleHeight;
    }
    .list{
      width: 400px;
      height: 100%;
      overflow-y: scroll;
      .item{
        width: 100%;
        border: 1px solid #d7d7d7;
        cursor: pointer;
      }
    }
    .chat{
      width: 380px;
      height: @boxHeight - @titleHeight - @inputHeight;
      overflow-y: scroll;
      .item{
        width: 100%;
        border: 1px solid #d7d7d7;
      }
    }
    .input-wrap{
      display: flex;
    }
  }
}
</style>

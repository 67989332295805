<template>
  <div class="wrap">
    <div class="header">
      <el-button size="small" @click="addItem()" type="primary">添加</el-button>
    </div>
    <div class="list">
      <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
      >
        <div class="block">
          <div class="left">
            <div class="space-wrap">
              <el-button size="mini" type="primary" @click="clearMessage(item)">清除信息</el-button>
            </div>
            <div class="messages">
              <div
                  v-for="(message, index) in item.messages"
                  :key="index"
                  class="message"
                  :class="{'success': message.success}"
              >
                {{ message.message }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="actions">
              <el-form size="mini" ref="form" :model="item.form" label-width="90px">
                <el-form-item label="状态">
                  <div v-if="!item.socket">
                    断线
                    <el-button size="mini" type="primary" @click="connect(item)" >链接服务器</el-button>
                  </div>
                  <div v-if="item.socket">
                    {{ item.status.login ? (item.status.online ? '唯一在线' : '登录') : '未登录' }}
                    <el-button size="mini" type="primary" @click="close(item)">断开服务器</el-button>
                  </div>
                </el-form-item>
                <template v-if="item.socket">
                  <el-form-item label="用户名">
                    <el-input size="mini"  v-model="item.form.username" :disabled="item.status.login"></el-input>
                  </el-form-item>
                  <el-form-item label="密码">
                    <el-input size="mini"  v-model="item.form.password" :disabled="item.status.login"></el-input>
                  </el-form-item>
                  <el-form-item label="登录">
                    <el-button size="mini"  type="primary" :disabled="item.status.login" @click="sendLogin(item)">登录</el-button>
                  </el-form-item>
                  <el-form-item label="用户信息">
                    <el-button size="mini"  type="primary" :disabled="!item.status.login" @click="sendUserInfo(item)">获取用户信息</el-button>
                  </el-form-item>
                  <el-form-item label="上线">
                    <el-button size="mini"  type="primary" :disabled="item.status.online" @click="onlineHandle(item)">{{ item.hasOtherOnlineUser ? '请求上线' : '上线' }}</el-button>
                  </el-form-item>
                  <el-form-item label="测试数据">
                    <el-button size="mini"  type="primary" :disabled="!item.status.login || !item.status.online" @click="sendDataList(item)">测试数据</el-button>
                  </el-form-item>
                  <el-form-item label="接受或拒绝">
                    <div
                        v-for="(replyOnline, index) in item.replyOnlineList"
                        :key="index"
                    >
                      {{ replyOnline.askUserId }} : {{ replyOnline.askUserName }}
                      <el-button size="mini"  type="warning" :disabled="!item.status.online" @click="sendReplyOnline(item, replyOnline.askUserId, true, index)">接受</el-button>
                      <el-button size="mini"  type="danger" :disabled="!item.status.online" @click="sendReplyOnline(item, replyOnline.askUserId, false, index)">拒绝</el-button>
                    </div>
                  </el-form-item>
                  <el-form-item label="其他用户">
                    <el-button size="mini"  type="primary" :disabled="!item.status.login" @click="sendOtherLoginUser(item)">查看其他用户</el-button>
                  </el-form-item>
                  <el-form-item label="退出登录">
                    <el-button size="mini"  type="primary" :disabled="!item.status.online" @click="sendLogout(item)">退出登录</el-button>
                  </el-form-item>
                </template>
              </el-form>
            </div>
          </div>
        </div>
        <div class="messages-block">
          <div class="left">
            <div class="messages">
              <div
                  v-for="vv in item.messageList"
                  :key="vv.id"
                  class="item"
                  @click="showChatList(item, vv)"
              >
                {{ vv.type }},  {{ vv.id }}
              </div>
            </div>
          </div>
          <div class="right">
            <template v-if="item.currentMessage">
              <div class="chatList">
                <div
                    v-for="(vv, ii) in item.currentMessage.chatList"
                    :key="ii"
                    class="item"
                >
                  {{ vv.type }}, {{ vv.text.substr(0, 16) }}, {{ vv.files.length }}
                </div>
              </div>
              <div class="reply-wrap">
                <div class="files">
                  <span>{{ item.files.length }}</span>
                  <el-button size="mini" type="primary" @click="clearFiles(item)">清除附件</el-button>
                </div>
                <div class="input">
                  <label>
                    <span class="upload">上传</span>
                    <input type="file" style="display:none" ref="uploader" @change="upload(item, $event)"  />
                  </label>
                  <el-input v-model="item.text"></el-input>
                  <el-button size="mini" type="primary" @click="submitMessage(item)">发送</el-button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {deepCopy} from "@/utils";

export default {
  name: "index",
  data() {
    return {
      list: [],
      defaultItem: {
        form: {
          username: '',
          password: '',
        },
        userInfo: null,
        hasOtherOnlineUser: false,
        token: '',
        status: {
          login: false,
          online: false,
        },
        messages: [],
        socket: null,
        replyOnlineList: [],
        timer: null,
        messageList: [],
        currentMessage: null,
        text: '',
        files: [],
      },
      url: 'ws://127.0.0.1:8989',
      env: process.env.NODE_ENV !== 'development' ? 'test' : 'dev',
    };
  },
  created() {
    if (this.env === 'dev') {
      this.url = 'ws://127.0.0.1:8989';
    } else {
      this.url = 'ws://api1.takkiv.com:8989';
    }
    this.addItem();
  },
  methods: {
    // 上传文件
    upload(item, event) {
      const input = event.target;
      let files = input.files;
      // console.log('files', files);
      if (files.length) {
        const file = files[0]

        if (file.size > 1024 * 1024 * 10) {
          input.value = '';
          this.$errorMessage('文件不能大于10M');
          return false;
        }

        let fileReader = new FileReader();
        fileReader.onload = () => {
          let base64 = fileReader.result;
          base64 = base64.replace(/^.*,/, '');
          console.log(fileReader, file);

          this.sendFile(item, item.currentMessage.item.type, item.currentMessage.item.id, base64, file.type, file.name.split('.').pop(), file.name)
          this.$refs['uploader'].value = '';
        };
        fileReader.readAsDataURL(file);
      }
    },
    // 发送信息
    submitMessage(item) {
      const files = [];
      item.files.forEach(vv => {
        files.push(vv.fileId);
      });
      this.sendReply(item, item.currentMessage.item.type, item.currentMessage.item.id, item.text, files);
    },
    // 清除附件
    clearFiles(item) {
      item.files = [];
    },
    // 显示聊天内容
    showChatList(item, vv) {
      item.currentMessage = {
        item: vv,
        chatList: [],
      };
      this.sendDataItem(item, vv.type, vv.id);
    },

    // 清除信息
    clearMessage(item) {
      item.messages = [];
    },
    // 添加
    addItem() {
      if (this.env === 'dev') {
        if (this.list.length % 2 === 0) {
          this.defaultItem.form.username = '349df0a4';
          this.defaultItem.form.password = '123456';
        } else {
          this.defaultItem.form.username = '8859c7af';
          this.defaultItem.form.password = '123456';
        }
      } else {
        if (this.list.length % 2 === 0) {
          this.defaultItem.form.username = 'a437330c';
          this.defaultItem.form.password = '123456';
        } else {
          this.defaultItem.form.username = '948b0314';
          this.defaultItem.form.password = '123456';
        }
      }

      let item = deepCopy(this.defaultItem);
      this.list.push(item);
    },
    // 添加信息
    addMessage(item, type, success, message, extMessage = '') {
      let messageText = `${type === 1 ? '↑' : '↓'} ${message}`;
      if (extMessage) {
        messageText += (' : ' + extMessage);
      }
      item.messages.push({
        type: type,
        success: success,
        message: messageText,
      });
    },

    // 发送信息
    formatData(token, topic, params = null, lang = 'zh') {
      return JSON.stringify({
        topic: topic,
        lang: lang,
        token: token,
        params: params,
      })
    },

    // 发送信息的封装
    send(item, topic, params, lang = 'zh') {
      if (item.socket) {
        item.socket.send(this.formatData(item.token, topic, params, lang));
      } else {
        this.$successMessage('当前没有链接');
      }
    },

    // 发送附件
    sendFile(item, type, id, fileBase64, mine, extension, name) {
      this.addMessage(item, 1, 1, '上传附件');
      let params = {
        "type": type,
        "id": id,
        "file": fileBase64,
        "mime": mine,
        "extension": extension,
        "name": name,
      };
      this.send(item, 'upload/file', params)
    },
    // 获取消息
    sendDataItem(item, type, id) {
      this.addMessage(item, 1, 1, `请求消息详情 ${type}, ${id}`);
      let params = {
        "type": type,
        "id": id,
      };
      this.send(item, 'data/item', params);
    },
    // 获取消息
    sendReply(item, type, id, text, files) {
      this.addMessage(item, 1, 1, `回复信息 ${type}, ${id}`);
      let params = {
        "type": type,
        "id": id,
        "text": text,
        "files": files,
      };
      this.send(item, 'reply', params);
    },

    // 登录
    sendLogin(item) {
      this.addMessage(item, 1, 1, '用户登录');
      this.send(item, 'login', item.form);
    },

    // 获取用户信息
    sendUserInfo(item) {
      this.addMessage(item, 1, 1, '获取用户信息');
      this.send(item, 'userinfo');
    },

    // 上线处理
    onlineHandle(item) {
      if (item.hasOtherOnlineUser) {
        this.sendAskForOnline(item);
      } else {
        this.sendOnline(item);
      }
    },

    // 直接上线
    sendOnline(item) {
      this.addMessage(item, 1, 1, '用户直接上线');
      this.send(item, 'login/online');
    },

    // 请求上线
    sendAskForOnline(item) {
      this.addMessage(item, 1, 1, '用户请求上线');
      this.send(item, 'askForOnline');
    },

    // 获取列表数据
    sendDataList(item) {
      this.addMessage(item, 1, 1, '获取信息列表');
      let params = {
        "offset": 0,
        "length": 100,
        "shopId": '',
      }
      this.send(item, 'data/list', params)
    },

    // 接受或者拒绝
    sendReplyOnline(item, askUserId, option, index) {
      this.addMessage(item, 1, 1, '回复其他用户的上线请求');
      let params = {
        askUserId: askUserId,
        option: !!option,
      };
      this.send(item, 'replyOnline', params);
      item.replyOnlineList.splice(index, 1);
    },

    // 查看其他用户
    sendOtherLoginUser(item) {
      this.addMessage(item, 1, 1, '请求是否有其他用户');
      this.send(item, 'otherLoginUser');
    },

    // 退出登录
    sendLogout(item) {
      this.addMessage(item, 1, 1, '退出登录');
      this.send(item, 'logout');
    },

    // 心跳
    sendPing(item){
      if (item.socket) {
        this.send(item, 'ping');
      }
    },

    // 断开连接
    close(item) {
      this.addMessage(item, 1, 1, '客户端主动断开连接');
      item.socket.close();
    },

    // 链接服务器
    connect(item) {
      this.addMessage(item, 1, 1, '连接服务器');
      item.socket = new WebSocket(this.url);

      // 开始设置发送的
      item.socket.addEventListener('open', (event) => {
        this.addMessage(item, 2, 1, '连接成功');
        if (!item.timer) {
          item.timer = setInterval(() => {
            this.sendPing(item);
          }, 10000);
        }
      });
      // 错误的信息
      item.socket.addEventListener('error', (event) => {
        this.addMessage(item, 2, 0, '连接失败');
        console.log('连接失败', event);
        item = deepCopy(this.defaultItem);
      });
      // 错误的信息
      item.socket.addEventListener('close', (event) => {
        this.addMessage(item, 2, 0, '服务器断开连接');
        item = deepCopy(this.defaultItem);
      });
      // 发送信息的
      item.socket.addEventListener('message', (event) => {
        try {
          let message = JSON.parse(event.data);
          switch (message.topic) {
            case 'login':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '登录成功');
                item.status.login = true;
                item.token = message.info.token;
              } else {
                this.addMessage(item, 2, !message.error, '登录失败', message.message);
              }
              break;

            case 'userinfo':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '获取用户信息成功');
                item.userInfo = message.data;
                item.hasOtherOnlineUser = message.info.hasOtherOnlineUser;
              } else {
                this.addMessage(item, 2, !message.error, '获取用户信息失败', message.message);
              }
              break;
            case 'online':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '上线成功');
                item.status.online = true;
              } else {
                this.addMessage(item, 2, !message.error, '上线失败', message.message);
              }
              break;
            case 'data/list':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '获取列表数据成功');
                item.messageList = [...item.messageList, ...message.data.list];
              } else {
                this.addMessage(item, 2, !message.error, '获取列表数据失败', message.message);
              }
              break;
            case 'data/item':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '获取详情数据成功', message.data.item.type + ',' + message.data.item.id);
                // 更新列表数据
                for (let i = 0; i < item.messageList.length; i++) {
                  if (item.messageList[i].type === message.data.item.type && item.messageList[i].id === message.data.item.id) {
                    item.messageList[i] = message.data.item;
                    break;
                  }
                }

                // 更新当前聊天数据
                if (item.currentMessage && item.currentMessage.item.type === message.data.item.type && item.currentMessage.item.id === message.data.item.id) {
                  item.currentMessage = message.data;
                }

              } else {
                this.addMessage(item, 2, !message.error, '获取详情数据失败', message.message);
              }
              break;
            case 'upload/file':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '上传附件成功');
                item.files.push(message.data);
              } else {
                this.addMessage(item, 2, !message.error, '上传附件失败', message.message);
              }
              break;
            case 'reply':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '回复信息成功');
              } else {
                this.addMessage(item, 2, !message.error, '回复信息失败', message.message);
              }
              break;
            case 'askForOnline':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '上线请求成功');
              } else {
                this.addMessage(item, 2, !message.error, '上线请求失败', message.message);
              }
              break;
            case 'askForOnline/notice':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '收到上线请求');
                item.replyOnlineList.push({
                  askUserId: message.data.userInfo.id,
                  askUserName: message.data.userInfo.name,
                });
              } else {
                this.addMessage(item, 2, !message.error, '收到上线请求失败？？', message.message);
              }
              break;
            case 'logout/force':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '收到强制退出登录请求');
                item.status.login = false;
                item.status.online = false;
              } else {
                this.addMessage(item, 2, !message.error, '收到强制退出登录请求失败？？', message.message);
              }
              break;
            case 'otherLoginUser':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '请求其他在线用户成功');
              } else {
                this.addMessage(item, 2, !message.error, '请求其他在线用户失败', message.message);
              }
              break;
            case 'logout':
              if (message.error === 0) {
                this.addMessage(item, 2, !message.error, '退出登录成功');
                item.token = '';
                item.status.login = false;
                item.status.online = false;
              } else {
                this.addMessage(item, 2, !message.error, '退出登录失败', message.message);
              }
              break;
            default:
              console.log('其他信息??', message);
              break;
          }
        } catch (e) {
          this.addMessage(item, 2, 0, '程序错误' + e);
          console.log("程序错误", e);
        }
      });
    },
  },
}
</script>

<style scoped lang="less">
@blockHeight: 500px;
.wrap{
  padding: 0 30px;
}
.header{
  text-align: left;
}
.list{
  padding: 10px 0 0;
  display: flex;
  flex-wrap: wrap;
  .item{
    width: 500px;
    border: #7d7d7d 1px solid;
    margin: 0 15px 15px 0;
    .block{
      display: flex;
      justify-content: space-between;
      height: @blockHeight;
      .left{
        width: 200px;
        .space-wrap{
          height: 30px;
        }
        .messages{
          width: 100%;
          border: #ccc 1px solid;
          text-align: left;
          height: @blockHeight - 30px;
          overflow-y: scroll;
          .message{
            color: #f00;
            &.success{
              color: #0f0;
            }
          }
        }
      }
      .right{
        width: 300px;
        height: 100%;
        .actions{
          border: #ccc 1px solid;
        }
      }
    }
    .messages-block{
      display: flex;
      .left{
        width: 200px;
        .messages{
          border: #ccc 1px solid;
          height: 300px;
          overflow-y: scroll;
          .item{
            text-align: left;
            font-size: 8px;
            margin-bottom: 0;
            cursor: pointer;
          }
        }
      }
      .right{
        @chatListHeight: 100%;
        @replyHeight: 70px;
        flex: 1 1 auto;
        padding: 0 0 @replyHeight;
        position: relative;
        .chatList{
          height: @chatListHeight;
          border: #ccc 1px solid;
          overflow-y: scroll;
          .item{
            width: 100%;
            margin-bottom: 0;
            font-size: 8px;
            text-align: left;
            text-indent: 5px;
          }
        }
        .reply-wrap{
          border: #ccc 1px solid;
          height: @replyHeight;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          .files{
            text-align: left;
          }
          .input{
            display: flex;
            .upload{
              display: block;
              width: 42px;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

</style>
